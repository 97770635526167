import fetchProductsFromGcJson from '@activebrands/core-web/utils/fetch-products-from-gc-json';
import { logger } from '@activebrands/core-web/utils/logger';
import { BasketItemModel, Reference } from 'types/basket';
import { Product } from 'types/tracking-events';

const mapProductIds = (items: BasketItemModel[], relation: string, seenIds: Set<unknown>): string[] => {
    return items.flatMap(item =>
        (item.productReference.relatedProducts ?? [])
            .filter((product: Reference) => {
                const isUpSell = product.relation === relation;
                const isNotSeen = !seenIds.has(product.id);
                if (isUpSell && isNotSeen) {
                    seenIds.add(product.id); // Add ID to seenIds here
                    return true; // Keep the product in the filtering
                }
                return false;
            })
            .map((product: Product) => product.id)
    );
};

export const useCentraRelations = async (items: BasketItemModel[], relation: string): Promise<Product[]> => {
    try {
        const seenIds = new Set(); // Track already added product IDs
        const combinedUpsellProducts: Product[] = []; // Temporary array to hold all upsell products

        const productIds = mapProductIds(items, relation, seenIds);

        if (productIds && productIds.length > 0) {
            const relatedProducts = await fetchProductsFromGcJson(productIds);
            for (const product of relatedProducts) {
                if (combinedUpsellProducts.length >= 9) break;
                if (product && product.id) {
                    combinedUpsellProducts.push(product);
                    seenIds.add(product.id); // Avoid duplicates
                }
            }
        }

        const rootItemIds = new Set(items.map(item => item.id.split('-')[0]));
        return combinedUpsellProducts.filter(
            product => !rootItemIds.has(product.id) // filter out the products that are already exists in basket
        );
    } catch (error) {
        logger.error('Error in useCentraRelations', {
            errorMessage: (error as Error).message,
            stack: (error as Error).stack,
            items,
            relation,
        });
        return [];
    }
};
